import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const InfoContainer = styled.div(
  ({ isPortrait, theme }) => css`
    grid-area: info;

    ${media.up('5xl')} {
      padding-top: ${isPortrait
        ? selectTheme('spacingTop.20xl')({ theme })
        : '0'};
    }
  `,
)

export const CtaContainer = styled.div`
  grid-area: cta;
`

export const VideoContainer = styled.div(
  ({ isPortrait, theme }) => css`
    grid-area: video;
    margin-left: ${isPortrait
      ? '0'
      : `-${selectTheme('spacingLeft.5xl')({ theme })}`};
    margin-right: ${isPortrait
      ? '0'
      : `-${selectTheme('spacingLeft.5xl')({ theme })}`};

    ${media.up('2xl')} {
      margin-left: 0;
      margin-right: 0;
    }
  `,
)
