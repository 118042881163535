import { array, object, oneOf, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { CtaContainer, InfoContainer, VideoContainer } from './base.styles'
import ButtonCta from './components/ButtonCta'
import Description from './components/Description'
import PreTitle from './components/PreTitle'
import Title from './components/Title'
import Video from './components/Video'
import Wrapper from './components/Wrapper'

const propTypes = {
  backgroundColor: oneOf(['gray', 'white']),
  ctaButtons: array,
  description: string,
  horizontalAlign: oneOf(['left', 'right']),
  landscapeThumbnail: object,
  portraitThumbnail: object,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  verticalAlign: oneOf(['top', 'bottom']),
  videoId: string,
}

const defaultProps = {
  backgroundColor: 'white',
  horizontalAlign: 'left',
  preTitleHeadingTag: 'h2',
  verticalAlign: 'bottom',
  titleHeadingTag: 'h1',
}

const GenericVideo = ({
  backgroundColor,
  ctaButtons,
  description,
  horizontalAlign,
  landscapeThumbnail,
  portraitThumbnail,
  preTitle,
  preTitleHeadingTag,
  title,
  titleHeadingTag,
  verticalAlign,
  videoId,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()

  backgroundColor = backgroundColor || defaultProps.backgroundColor
  horizontalAlign = horizontalAlign || defaultProps.horizontalAlign
  verticalAlign = verticalAlign || defaultProps.verticalAlign
  titleHeadingTag = titleHeadingTag || defaultProps.titleHeadingTag
  preTitleHeadingTag = preTitleHeadingTag || defaultProps.preTitleHeadingTag
  const buttonCta = ctaButtons && ctaButtons[0]
  const currentThumbnail = portraitThumbnail || landscapeThumbnail
  const isPortrait = !!portraitThumbnail

  const handleVideoClick = () => {
    if (!title) return

    triggerEvent({
      label: `${currentThumbnail?.description} | play`,
      pageInteraction: true,
    })
  }

  return (
    <Wrapper
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
      backgroundColor={backgroundColor}
      isPortrait={isPortrait}
      {...others}
    >
      <InfoContainer isPortrait={isPortrait}>
        <PreTitle as={preTitleHeadingTag}>{preTitle}</PreTitle>
        <Title as={titleHeadingTag}>{title}</Title>
        <Description>{description}</Description>
      </InfoContainer>
      <CtaContainer>
        <ButtonCta buttonCta={buttonCta} />
      </CtaContainer>
      <VideoContainer isPortrait={isPortrait}>
        <Video
          onClick={handleVideoClick}
          thumbnail={currentThumbnail}
          videoId={videoId}
          verticalAlign={verticalAlign}
          isPortrait={isPortrait}
        />
      </VideoContainer>
    </Wrapper>
  )
}

GenericVideo.propTypes = propTypes
GenericVideo.defaultProps = defaultProps

export default GenericVideo
