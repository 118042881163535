import { bool, node, oneOf } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  backgroundColor: oneOf(['gray', 'white']),
  children: node,
  horizontalAlign: oneOf(['left', 'right']),
  isPortrait: bool,
  verticalAlign: oneOf(['top', 'bottom']),
}

const defaultProps = {
  backgroundColor: 'white',
  horizontalAlign: 'left',
  isPortrait: false,
  verticalAlign: 'bottom',
}

const Wrapper = ({
  backgroundColor,
  children,
  horizontalAlign,
  isPortrait,
  verticalAlign,
  ...others
}) => {
  const currentBackgroundColor =
    backgroundColor === 'gray' ? 'neutral.10' : 'neutral.0'

  return (
    <Section backgroundColor={currentBackgroundColor} {...others}>
      <Container>
        <Grid
          isPortrait={isPortrait}
          horizontal={horizontalAlign}
          vertical={verticalAlign}
        >
          {children}
        </Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
