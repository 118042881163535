import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { Cta as CreditasCta } from 'components/Cta'

const Cta = styled(CreditasCta)`
  margin-top: ${selectTheme('spacingTop.8xl')};
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;
`

export { Cta }
