import { func } from 'prop-types'

import PlayIcon from 'images/play-button.png'

import { Image, Wrapper } from './styles'

const propTypes = {
  onClick: func,
}

const PlayButton = ({ onClick }) => {
  return (
    <Wrapper data-testid="cta-generic-content-video" onClick={onClick}>
      <Image src={PlayIcon} alt="Reclame aqui" />
    </Wrapper>
  )
}

PlayButton.propTypes = propTypes

export default PlayButton
