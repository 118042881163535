import { useState } from 'react'
import { bool, func, node, object, oneOf, string } from 'prop-types'

import Figure from './components/Figure'
import PlayButton from './components/PlayButton'

import { Iframe, LazyImage, Wrapper } from './styles'

const propTypes = {
  children: node,
  isPortrait: bool,
  onClick: func,
  thumbnail: object,
  verticalAlign: oneOf(['top', 'bottom']),
  videoId: string,
}

const defaultProps = {
  isPortrait: false,
  onClick: () => {},
  verticalAlign: 'bottom',
}

const Video = props => {
  const {
    isPortrait,
    onClick,
    thumbnail,
    verticalAlign,
    videoId,
    ...others
  } = props
  const [isClick, setIsClick] = useState(false)
  if (!videoId || !thumbnail) return null

  const handleClick = e => {
    onClick(e)

    setIsClick(true)
  }

  return (
    <Figure verticalAlign={verticalAlign} isPortrait={isPortrait}>
      <Wrapper data-testid="generic-content-video" {...others}>
        {isClick && (
          <Iframe
            data-testid="iframe-generic-content-video"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            allow="accelerometer; autoplay; gyroscope;"
          />
        )}
        <LazyImage {...thumbnail} alt={thumbnail?.description} />

        <PlayButton onClick={handleClick} />
      </Wrapper>
    </Figure>
  )
}

Video.propTypes = propTypes
Video.defaultProps = defaultProps

export default Video
