import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as CreditasGrid } from 'components/Grid'

export const Section = styled.section(
  ({ backgroundColor, theme }) => css`
    background-color: ${selectTheme(`colors.${backgroundColor}`)({ theme })};
  `,
)

export const Container = styled(CreditasContainer)`
  padding-top: ${selectTheme('spacingTop.15xl')};
  padding-bottom: ${selectTheme('spacingBottom.15xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.21xl')};
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`

export const Grid = styled(CreditasGrid)(
  ({ horizontal, isPortrait, vertical }) => {
    const gridOption = {
      landscape: css`
        grid-template-rows: auto 1fr;
        grid-template-areas: ${vertical === 'top'
          ? `'${'info '.repeat(4)}'
          '${'cta '.repeat(4)}'
          '${'video '.repeat(4)}'`
          : `'${'video '.repeat(4)}'
          '${'info '.repeat(4)}'
          '${'cta '.repeat(4)}'`};

        ${media.up('2xl')} {
          grid-template-areas: ${vertical === 'top'
            ? `'${'info '.repeat(8)}'
            '${'cta '.repeat(8)}'
            '${'video '.repeat(8)}'`
            : `'${'video '.repeat(8)}'
            '${'info '.repeat(8)}'
            '${'cta '.repeat(8)}'`};
        }

        ${media.up('5xl')} {
          grid-template-areas: ${horizontal === 'right'
            ? `'${'info '.repeat(5)} . ${'video '.repeat(6)}'
            '${'cta '.repeat(2)} . . . . ${'video '.repeat(6)}'`
            : `'${'video '.repeat(6)} . ${'info '.repeat(5)}'
            '${'video '.repeat(6)} . ${'cta '.repeat(2)} . . .'`};
        }
      `,
      portrait: css`
        grid-template-rows: auto 1fr;
        grid-template-areas:
          '${'info '.repeat(4)}'
          '${'cta '.repeat(4)}'
          '${'video '.repeat(4)}';

        ${media.up('2xl')} {
          grid-template-areas:
            '${'info '.repeat(8)}'
            '${'cta '.repeat(8)}'
            '${'video '.repeat(8)}';
        }

        ${media.up('5xl')} {
          grid-template-areas: ${horizontal === 'right'
            ? `'. ${'info '.repeat(5)} . ${'video '.repeat(4)} .'
            '. ${'cta '.repeat(3)} . . . ${'video '.repeat(4)} .'`
            : `'. ${'video '.repeat(4)} . ${'info '.repeat(5)} .'
            '. ${'video '.repeat(4)} . ${'cta '.repeat(2)} . . . .'`};
        }
      `,
    }
    return gridOption[isPortrait ? 'portrait' : 'landscape']
  },
)
