import { styled } from '@creditas-ui/system'

import { LazyImage as LazyImageComponent } from 'components/LazyImage'

const LazyImage = styled(LazyImageComponent)`
  height: auto;
  width: 100%;
  display: block;
  z-index: 0;
`

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: block;
  position: relative;
`

const Iframe = styled.iframe`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`

export { Iframe, Wrapper, LazyImage }
