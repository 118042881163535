import React from 'react'
import { object } from 'prop-types'

import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Cta } from './styles'

const propTypes = {
  buttonCta: object,
}

const defaultProps = {
  buttonCta: {},
}

const ButtonCta = ({ buttonCta }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta) return null

  const ctaProps = {
    ...buttonCta,
    analyticEvent: null,
    type: buttonCta?.actionType || 'link',
    url: buttonCta?.actionHref,
  }

  const analyticEvent = {
    label: `${ctaProps?.label || ctaProps?.url}`,
    pageInteraction: ctaProps?.type !== 'link',
  }

  return (
    <Cta
      element={ButtonPrimaryTextOnly}
      width="flexible"
      size="large"
      {...ctaProps}
      onClick={() => triggerEvent(analyticEvent)}
    >
      {ctaProps?.label}
    </Cta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
