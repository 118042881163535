import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const Wrapper = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  box-shadow: ${selectTheme('shadow.md')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled(LazyImage)`
  width: 64px;
  height: 64px;

  ${media.up('5xl')} {
    width: 120px;
    height: 120px;
  }
`
