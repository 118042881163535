import { node } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: node,
}

const PreTitle = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography
      color="neutral.60"
      variant={{
        _: 'exceptionSmBold',
        '5xl': 'exceptionMdBold',
      }}
      data-testid="generic-content-pretitle"
      {...others}
    >
      {children}
    </Typography>
  )
}

PreTitle.propTypes = propTypes

export default PreTitle
