import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Wrapper = styled.div(
  ({ isPortrait, verticalAlign }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    border: none;
    margin-bottom: ${isPortrait || verticalAlign === 'top' ? '0' : '32px'};
    margin-top: ${isPortrait || verticalAlign === 'top' ? '32px' : '0'};

    ${media.up('5xl')} {
      margin-top: 0;
      margin-bottom: 0;
    }
  `,
)

const FigureWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
`

export { Wrapper, FigureWrapper }
