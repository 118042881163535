import { node } from 'prop-types'

import { FigureWrapper, Wrapper } from './styles'

const propTypes = {
  children: node,
}

const Figure = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Wrapper data-testid="generic-content-image" {...others}>
      <FigureWrapper>{children}</FigureWrapper>
    </Wrapper>
  )
}

Figure.propTypes = propTypes

export default Figure
